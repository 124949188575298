import React, { useState, useEffect, useContext } from 'react';
import { Storage } from 'aws-amplify';
import { Spin } from 'antd';
import AdminContext from '../../../context/Admin/AdminContext';
import * as styles from '../../../styles/admin/ProfileView.module.scss';
import Authenticate from '../../Identity/Authenticate';
import {
  logout, updateUserImage, updatePassword, validatePasswordFields,
  getCurrentUser,
} from '../../Shared/Services/AuthService';
import ChangeName from './ChangeName';

/**
 *
 *
 * @return {*}
 */
function ProfileView() {
  const { profileInfo, setProfileInfo } = useContext(AdminContext);
  const [tempProfileInfo, setTempProfileInfo] = useState({});
  const [isFieldEditable, setFieldEditable] = useState({
    password: false,
  });
  const [loading, setLoading] = useState({
    password: false,
    sync: false,
    profilePic: false,
  });

  const [loggedInUser, setLoggedInUser] = useState(null);
  const [disableLogoutCta, setDisableLogoutCta] = useState(false);

  /**
   *
   *
   * @param {*} e
   */
  const handleBrokenImage = (e) => {
    e.target.src = '/images/learner/avatar.png';
  };

  /**
   *
   *
   * @param {*} field
   */
  const revertFieldInfo = (field) => {
    if (field in tempProfileInfo) {
      setTempProfileInfo({
        ...tempProfileInfo,
        [field]: profileInfo[field],
      });
    }
  };

  /**
   *
   *
   * @param {*} field
   */
  const toggleEditableField = (field) => {
    revertFieldInfo(field);
    setFieldEditable({
      ...isFieldEditable,
      [field]: !isFieldEditable[field],
    });
  };

  /**
   *
   *
   * @param {*} field
   */
  const toggleLoadingForField = (field) => {
    setLoading((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  /**
   *
   * handles input state and sets the state.
   * @param {*} e
   */
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTempProfileInfo({
      ...tempProfileInfo,
      [name]: value,
    });
  };

  /**
   *
   * updates the password in cognito.
   */
  const changePassword = async () => {
    toggleLoadingForField('password');
    const { oldPassword, newPassword } = tempProfileInfo;
    if (validatePasswordFields(oldPassword, newPassword)) {
      await updatePassword(loggedInUser, oldPassword, newPassword);
    }
    toggleLoadingForField('password');
    toggleEditableField('password');
  };

  /**
   *
   * uploads image to s3.
   * @param {*} file
   */
  const uploadImage = async (file) => {
    if (!file) {
      return;
    }

    toggleLoadingForField('profilePic');
    try {
      const userId = profileInfo?.id;
      await updateUserImage(userId, file);
      setProfileInfo({
        ...profileInfo,
        profilePicture: await Storage.get(userId),
      });
    } catch {
      // console.log(e);
    }
    toggleLoadingForField('profilePic');
  };

  const logoutHandler = () => {
    setDisableLogoutCta(true);
    logout();
  };

  useEffect(async () => {
    const currentUser = await getCurrentUser();
    setLoggedInUser(currentUser);
  }, []);

  useEffect(() => {
    if (profileInfo) {
      setTempProfileInfo(profileInfo);
    }
  }, [profileInfo]);

  return (
    <div
      className={`${styles.account} library-shaw-icon-new`}
      style={{ height: '100vh' }}
    >
      {loading.sync && (<div className={styles.loader}><Spin tip="Loading..." /></div>)}
      <p className={styles.title}>Account & Settings</p>
      <div className={styles.section}>
        <div className={styles.userProfile}>
          {loading.profilePic && <Spin className={styles.profilePicLoader} />}
          <div className={styles.profilePic}>
            <img
              onError={handleBrokenImage}
              src={`${profileInfo?.profilePicture ? profileInfo?.profilePicture : '/images/learner/avatar.png'}`}
              alt="user-profile"
            />
            <i className="icon-new-Edit1" />
            <label htmlFor="file">
              <input
                id="file"
                accept="image/png, image/jpeg"
                type="file"
                onChange={(e) => uploadImage(e.target.files[0])}
              />
            </label>
          </div>
        </div>
        <ChangeName
          tempProfileInfo={tempProfileInfo}
          handleChange={handleChange}
          setTempProfileInfo={setTempProfileInfo}
        />
      </div>
      <div className={styles.fieldGroup}>
        <div className={styles.fieldTitleSection}>
          <p className={styles.fieldTitle}>Email address</p>
        </div>
        <p className={styles.fieldValue}>{loggedInUser?.attributes?.email}</p>
      </div>
      <div className={styles.fieldGroup}>
        <div className={`${styles.fieldTitleSection} ${styles.fieldTitlePass}`}>
          <p className={styles.fieldTitle}>Password</p>
          {isFieldEditable.password
            ? (
              <div className={styles.ctaSection}>
                {loading.password && <Spin />}
                <button
                  id="admin-profile-confirm-new-password"
                  type="button"
                  onClick={changePassword}
                  disabled={loading.password}
                  className={styles.buttonSave}
                >
                  Confirm New Password
                </button>
                <button
                  id="admin-profile-cancel-password-changes"
                  type="button"
                  onClick={() => toggleEditableField('password')}
                  className={styles.buttonCancel}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                id="admin-profile-change-password"
                type="button"
                onClick={() => toggleEditableField('password')}
                className={styles.button}
              >
                Change password
              </button>
            )}
        </div>
        {isFieldEditable.password
          ? (
            <div className={styles.editableField}>
              <div className={styles.labelpassword}>
                <div>
                  <span className={styles.labeltext}>Enter your old password</span>
                </div>
                <div>
                  <input
                    type="password"
                    name="oldPassword"
                    placeholder="Enter your old password"
                    onChange={handleChange}
                    className={styles.inputStyle}
                  />
                </div>

              </div>
              <div className={styles.labelpassword}>
                <div>
                  <span className={styles.labeltext}>Enter your new password</span>
                </div>
                <div>
                  <input
                    type="password"
                    name="newPassword"
                    placeholder="Enter your new password"
                    onChange={handleChange}
                    className={styles.inputStyleNew}
                  />
                </div>

                <h6 className={styles.passwordCriteriaView}>
                  Password must be a minimum of 8 characters long.
                </h6>
              </div>

            </div>
          )
          : <p className={styles.fieldTitle}>•••••••••</p>}
      </div>
      <div className={styles.buttonWrapper}>
        <button
          id="admin-profile-logout"
          type="button"
          className={styles.logoutCta}
          disabled={disableLogoutCta}
          onClick={logoutHandler}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default Authenticate(ProfileView);
