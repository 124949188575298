import React from 'react';
import PropTypes from 'prop-types';
import Authenticate from '../../../components/Identity/Authenticate';
import Layout from '../../../components/Admin/Layout';
import * as styles from '../../../styles/admin/Account.module.scss';
import ProfileView from '../../../components/Admin/Profile/ProfileView';
import { AdminProvider } from '../../../context/Admin/AdminContext';

function Profile(props) {
  const { identityProviderClient } = props;

  return (
    <AdminProvider>
      <div className={styles.accountPageWrapper}>
        <Layout
          title=""
          Component={ProfileView}
          tabs={{}}
          activeTab=""
          identityProviderClient={identityProviderClient}
        />
      </div>
    </AdminProvider>
  );
}
Profile.propTypes = {
  identityProviderClient: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Authenticate(Profile);
