// extracted by mini-css-extract-plugin
export var account = "ProfileView-module--account--1MRGS";
export var loader = "ProfileView-module--loader--C_MAa";
export var title = "ProfileView-module--title--2LwyE";
export var labelpassword = "ProfileView-module--labelpassword--1KtbU";
export var labeltext = "ProfileView-module--labeltext--1Nyi6";
export var inputStyle = "ProfileView-module--inputStyle--36LgQ";
export var inputStyleNew = "ProfileView-module--inputStyleNew--1ae-H";
export var passwordCriteriaView = "ProfileView-module--passwordCriteriaView--aybF5";
export var section = "ProfileView-module--section--2Eo5o";
export var userProfile = "ProfileView-module--userProfile--1WxNq";
export var profilePicLoader = "ProfileView-module--profilePicLoader--3KUyD";
export var profilePic = "ProfileView-module--profilePic--3D_e_";
export var editableField = "ProfileView-module--editableField--3cou5";
export var editName = "ProfileView-module--editName--ArNqg";
export var error = "ProfileView-module--error--mh-jf";
export var ctaSection = "ProfileView-module--ctaSection--1zPr2";
export var button = "ProfileView-module--button--1BEYE";
export var buttonSave = "ProfileView-module--buttonSave--4XZmh";
export var buttonCancel = "ProfileView-module--buttonCancel--7CfMa";
export var fieldGroup = "ProfileView-module--fieldGroup---O0qD";
export var fieldTitleSection = "ProfileView-module--fieldTitleSection--Lx7NL";
export var fieldTitle = "ProfileView-module--fieldTitle--2NmoT";
export var fieldTitlePass = "ProfileView-module--fieldTitlePass--2JRq5";
export var fieldValue = "ProfileView-module--fieldValue--1se6a";
export var buttonWrapper = "ProfileView-module--buttonWrapper--2YPX1";
export var logoutCta = "ProfileView-module--logoutCta--3tYac";