import React, { useState, useContext } from 'react';
import dayjs from 'dayjs';
import { Spin } from 'antd';
import AdminContext, { ProfileInfo } from '../../../context/Admin/AdminContext';
import * as styles from '../../../styles/admin/ChangeName.module.scss';

import { isBDMUser, postEvent, toaster } from '../../../services/utils';
import { syncCustomerData } from '../Services/ChargebeeService';
import { Role } from '../../../models';
import { getCurrentUser, updateName } from '../../Shared/Services/AuthService';

type Props = {
  tempProfileInfo: ProfileInfo,
  handleChange: (e:React.ChangeEvent<HTMLInputElement>) => void,
  setTempProfileInfo: React.Dispatch<React.SetStateAction<ProfileInfo>>,
}

const ChangeName:React.FC<Props> = ({ tempProfileInfo, handleChange, setTempProfileInfo }) => {
  const { profileInfo, setProfileInfo } = useContext(AdminContext);
  const [editName, setEditName] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const triggerIdentifyEvent = async () => {
    const fullName = `${tempProfileInfo?.firstName} ${tempProfileInfo?.lastName}`;
    const eventPayload = {
      userId: profileInfo?.id,
      enterpriseId: profileInfo?.enterpriseID,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      traits: {
        email: profileInfo?.email,
        firstName: tempProfileInfo?.firstName,
        lastName: tempProfileInfo?.lastName,
        name: fullName,
        roles: profileInfo?.roles,
        createdAt: profileInfo?.createdAt,
        lastActive: dayjs().toISOString(),
        exclude: isBDMUser(profileInfo?.email),
      },
    };
    postEvent(eventPayload, '/identify');
  };

  /**
   *
   * updates the name in both cognito
   * and datastore.
   */
  const changeName = async () => {
    setEditName(true);
    setIsLoading(true);
    try {
      if (!tempProfileInfo?.firstName) {
        toaster('Please enter your first name', 'error');
        setIsLoading(false);
        return;
      }
      if (!tempProfileInfo?.lastName) {
        toaster('Please enter your last name', 'error');
        setIsLoading(false);
        return;
      }
      if (tempProfileInfo?.firstName && tempProfileInfo?.lastName) {
        const fullName = `${tempProfileInfo?.firstName} ${tempProfileInfo?.lastName}`;
        const currentUser = await getCurrentUser();
        await updateName(currentUser, { ...tempProfileInfo, name: fullName });
        triggerIdentifyEvent();
        setProfileInfo({
          ...profileInfo,
          name: fullName,
          firstName: tempProfileInfo?.firstName,
          lastName: tempProfileInfo?.lastName,
        });
        if (profileInfo?.roles?.[0] === Role.OWNER) {
          const enterpriseId = profileInfo?.enterpriseID || '';
          syncCustomerData({
            name: fullName,
          }, enterpriseId);
        }
      }
    } catch {
      // console.log(err);
    }
    setEditName(false);
    setIsLoading(false);
  };

  const cancelChanges = () => {
    setEditName(false);
    setTempProfileInfo((prevState) => ({
      ...prevState,
      firstName: profileInfo?.firstName,
      lastName: profileInfo?.lastName,
    }));
  };

  return (
    <div className={styles.changeNameContainer}>
      <div>
        {editName
          ? (
            <div className={`${styles.editableField} ${styles.editName}`}>
              <label htmlFor="firstName">
                First Name*
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={tempProfileInfo?.firstName || ''}
                  placeholder="First Name"
                  onChange={handleChange}
                  className={`${!tempProfileInfo?.firstName ? styles.error : ''}`}
                />
              </label>
              <label htmlFor="lastName">
                Last Name*
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={tempProfileInfo?.lastName || ''}
                  placeholder="Last Name"
                  onChange={handleChange}
                  className={`${!tempProfileInfo?.lastName ? styles.error : ''}`}
                />
              </label>
            </div>
          )
          : <p>{profileInfo.name}</p>}
      </div>
      <div>
        {editName
          ? (
            <div className={styles.ctaSection}>
              {isLoading && <Spin />}
              <button
                id="admin-profile-save"
                type="button"
                onClick={changeName}
                disabled={isLoading}
                className={styles.buttonSave}
              >
                Save
              </button>
              <button
                id="admin-profile-cancel-name-changes"
                type="button"
                onClick={cancelChanges}
                className={styles.buttonCancel}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              id="admin-profile-edit"
              type="button"
              onClick={() => setEditName(true)}
              className={styles.button}
            >
              Edit
            </button>
          )}
      </div>
    </div>
  );
};

export default ChangeName;
